import { AssetSurfaceData, AssetSurfacesInformationDto, AssetSurfacesInformationModel, AssetWorkPlanStateDistributionModel } from '@foxeet/domain';
import { FormUtils } from './form.functions';
import { FormArray, FormGroup } from '@angular/forms';
import { AppraisalAssetSurfaceSelectedDataUtils } from './surface-selector.functions';
import { isNil } from './javascript.functions';

export const typesOfSurfaces = ['cadastralSurfaces', 'registeredSurfaces', 'checkedSurfaces'];

export enum SurfacesIds {
  cadastralSurfaces_totaltotalPlotSurfaceId = 0,
  cadastralSurfaces_totalUsefulSurfaceId = 1,
  cadastralSurfaces_totalBuiltSurfaceWithoutCommonSurfacesId = 2,
  cadastralSurfaces_totalBuiltSurfaceWithCommonSurfacesId = 3,
  cadastralSurfaces_totalCoveredTerraceSurfaceId = 4,
  cadastralSurfaces_totalUncoveredTerraceSurfaceId = 5,
  registeredSurfaces_totaltotalPlotSurfaceId = 6,
  registeredSurfaces_totalUsefulSurfaceId = 7,
  registeredSurfaces_totalBuiltSurfaceWithoutCommonSurfacesId = 8,
  registeredSurfaces_totalBuiltSurfaceWithCommonSurfacesId = 9,
  registeredSurfaces_totalCoveredTerraceSurfaceId = 10,
  registeredSurfaces_totalUncoveredTerraceSurfaceId = 11,
  checkedSurfaces_totaltotalPlotSurfaceId = 12,
  checkedSurfaces_totalUsefulSurfaceId = 13,
  checkedSurfaces_totalBuiltSurfaceWithoutCommonSurfacesId = 14,
  checkedSurfaces_totalBuiltSurfaceWithCommonSurfacesId = 15,
  checkedSurfaces_totalCoveredTerraceSurfaceId = 16,
  checkedSurfaces_totalUncoveredTerraceSurfaceId = 17,
}

export const getBasicSurfaceObject = (): AssetSurfacesInformationDto => {
  const enumSurfaces = FormUtils.fromEnumToKeyValueArray(SurfacesIds, 'SurfacesIds');

  const surfaces: AssetSurfacesInformationDto = {
    cadastralSurfaces: null,
    registeredSurfaces: null,
    checkedSurfaces: null,
    surfacesObservations: '',
  };
  typesOfSurfaces.forEach((tSurface) => {
    const surfacesObject = {
      totalPlotSurface: 1000,
      totalUsefulSurface: 1000,
      totalBuiltSurfaceWithoutCommonSurfaces: 1000,
      totalBuiltSurfaceWithCommonSurfaces: 1000,
      totalCoveredTerraceSurface: 1000,
      totalUncoveredTerraceSurface: 1000,
      totalPlotSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalPlotSurfaceId`)?.key,
      totalUsefulSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalUsefulSurfaceId`)?.key,
      totalBuiltSurfaceWithoutCommonSurfacesId: enumSurfaces.find((el) => el.value == `${tSurface}_totalBuiltSurfaceWithoutCommonSurfacesId`)?.key,
      totalBuiltSurfaceWithCommonSurfacesId: enumSurfaces.find((el) => el.value == `${tSurface}_totalBuiltSurfaceWithCommonSurfacesId`)?.key,
      totalCoveredTerraceSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalCoveredTerraceSurfaceId`)?.key,
      totalUncoveredTerraceSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalUncoveredTerraceSurfaceId`)?.key,
      allFields: [
        'totalPlotSurface',
        'totalUsefulSurface',
        'totalBuiltSurfaceWithoutCommonSurfaces',
        'totalBuiltSurfaceWithCommonSurfaces',
        'totalCoveredTerraceSurface',
        'totalUncoveredTerraceSurface',
      ],
    };
    surfaces[tSurface] = surfacesObject;
  });
  return surfaces;
};

export const assignResultGlobalFilter = (
  el: FormGroup,
  selectedSurface: number,
  selectedSurfaceValue: number,
  assignedUnitValue: number,
  onChangeSelectedSurface: (selectedSurface: number, el: FormGroup) => void,
) => {
  el.get('adoptedSurfaceId')?.setValue(selectedSurface);
  el.get('adoptedSurface')?.setValue(selectedSurfaceValue);
  el.get('assignedUnitValue')?.setValue(assignedUnitValue);

  onChangeSelectedSurface(selectedSurface, el);
};

export const checkUseandTypologyGlobalFilter = (data: any, onChangeSelectedSurface: (selectedSurface: number, el: FormGroup) => void) => {
  const { use, elUse, typology, elTypology, el, selectedSurface, selectedSurfaceValue, assignedUnitValue, finish } = data;
  if (isNil(use) || (!isNil(use) && use === elUse)) {
    if (isNil(typology) || (!isNil(typology) && typology === elTypology)) {
      assignResultGlobalFilter(el, selectedSurface, selectedSurfaceValue, assignedUnitValue, onChangeSelectedSurface);
    } else {
      finish.push(true);
    }
  } else {
    finish.push(true);
  }
};

export const setGlobalFilterModalResult = (formArray: FormArray, result: any, onChangeSelectedSurface: (selectedSurface: number, el: FormGroup) => void) => {
  const { use, typology, selectedSurface, minSurface, maxSurface, assignedUnitValue } = result;

  let finish = [];
  let i = 0;
  while (i < formArray.controls.length) {
    const el = formArray.controls[i] as FormGroup;

    const elUse = el.get('use')?.value;
    const elTypology = el.get('typology')?.value;

    const selectedSurfaceValue = AppraisalAssetSurfaceSelectedDataUtils.getSurfaceValueByAdoptedSurfaceId(el.get('surfaces')?.value, selectedSurface);

    if (!isNil(selectedSurfaceValue)) {
      if (!isNil(maxSurface) || !isNil(minSurface) || !isNil(use) || !isNil(typology)) {
        if (
          (!isNil(maxSurface) && isNil(minSurface) && selectedSurfaceValue <= maxSurface) ||
          (isNil(maxSurface) && !isNil(minSurface) && selectedSurfaceValue >= minSurface) ||
          (!isNil(maxSurface) && !isNil(minSurface) && selectedSurfaceValue <= maxSurface && selectedSurfaceValue >= minSurface)
        ) {
          checkUseandTypologyGlobalFilter({ use, elUse, typology, elTypology, el, selectedSurface, selectedSurfaceValue, assignedUnitValue, finish }, onChangeSelectedSurface);
        } else {
          if (isNil(maxSurface) && isNil(minSurface)) {
            checkUseandTypologyGlobalFilter({ use, elUse, typology, elTypology, el, selectedSurface, selectedSurfaceValue, assignedUnitValue, finish }, onChangeSelectedSurface);
          } else {
            finish.push(true);
          }
        }
      } else {
        assignResultGlobalFilter(el, selectedSurface, selectedSurfaceValue, assignedUnitValue, onChangeSelectedSurface);
      }
    } else {
      finish.push(true);
    }

    i++;
  }

  return finish;
};
