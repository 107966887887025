import { AppraiserStates, FloorTypes, PaymentOptions, StreetTypes, UserFileTypes } from '../enums';
import { LicenseeUserModel } from './license.model';

export interface UserModel {
  id: number;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImageFileName: string;
  profileImageFileWebPath: string;
  isActive: boolean;
  isTemporalPassword: boolean;
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
  licenseeUsers: LicenseeUserModel[];
  addressGeneratedName: string;
  block: string;
  censusSectionName: string;
  country: string;
  countryThreeLetterISORegionName: string;
  degree: string;
  dgcCodVia: number;
  externalCode: string;
  extract: string;
  floorType: FloorTypes;
  fullAddressGeneratedName: string;
  gate: string;
  haveToShowWelcomePopup: boolean;
  haveToShowTourAppraisal: boolean;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodVia: string;
  internalObservations: string;
  languageCode: string;
  latitude: number;
  locality: string;
  longitude: number;
  nationalIdentityDocument: string;
  observations: string;
  paymentOption: PaymentOptions;
  portal: string;
  position: string;
  postalCode: string;
  province: string;
  sharingId: string;
  stairs: string;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  streetNotExists: boolean;

  haveAcceptedCookies: boolean;
  acceptedCookiesDateTime: any;
  cultureCodeCurrency: string;
  cultureCodeLanguage: string;

  haveToShowPartnerModal: boolean;
  notShowImportAssetsWarning: boolean;
  notShowTechnicalValidationInfoModal: boolean;
  isVerifiedAppraiser: boolean;
}

export interface UserIndexModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  isActive: boolean;
  licenseeUserRoles: string[];
  nationalIdentityDocument: string;
  appraisalsInProcessCount: number;
  locality: string;
  province: string;
  country: string;
  postalCode: string;
  isPartner: boolean;
  externalCode: string;
  position: string;
  extract: string;
  isExternalTechnicalManager: boolean;
  deadlineGrade: string | number;
  executionGrade: string | number;
  appraiserState: AppraiserStates;
  isVerifiedAppraiser: boolean;
}

export interface UserFilterModel {
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  possibleOrderFieldNames: string[];
  id: number;
  ids: number[];
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
  firstNameContains: string;
  lastNameContains: string;
  emailContains: string;
  fullName: string;
  fullNameContains: string;
  licenseeId: number;
  licenseeIds: number[];
  licenseeIdOrs: number[];
  licenseeUserRoleTypes: number[];
  // TODO: review not sure if Map or Object {key: value}
  licenseeIdAndLicenseeUserRoleType: Map<number, number>;
  code: string;
  countryThreeLetterISORegionName: string;
  dgcCodVia: number;
  ineCodVia: string;
  distanceBetweenPointLatitude: number;
  distanceBetweenPointLongitude: number;
  distanceBetweenPointMaxMeters: number;
  distanceBetweenPointMinMeters: number;
  ineCodLocality: number;
  ineCodMun: number;
  ineCodProv: number;
  isPartner: boolean;
  locality: string;
  localityContains: string;
  nationalIdentityDocument: string;
  nationalIdentityDocumentContains: string;
  postalCode: string;
  postalCodeContains: string;
  province: string;
  provinceContains: string;
  streetName: string;
  streetNameContains: string;
}

export interface UserEditModel {
  confirmPassword: string;
  email: string;
  firstName: string;
  id: number;
  isActive: boolean;
  lastName: string;
  licenseeUsers: LicenseeUserModel[];
  password: string;
  phoneNumber: string;
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
  addressGeneratedName: string;
  block: string;
  censusSectionName: string;
  country: string;
  countryThreeLetterISORegionName: string;
  degree: string;
  dgcCodVia: number;
  externalCode: string;
  extract: string;
  floorType: FloorTypes;
  fullAddressGeneratedName: string;
  gate: string;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodVia: string;
  internalObservations: string;
  locality: string;
  nationalIdentityDocument: string;
  observations: string;
  paymentOption: PaymentOptions;
  portal: string;
  position: string;
  postalCode: string;
  province: string;
  stairs: string;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  cultureCodeCurrency: string;
  cultureCodeLanguage: string;
}

export interface UserFileModel {
  userId: number;
  userFileType: UserFileTypes;
  fileName: string;
  expirationDateTime?: string;
  addedDateTime?: string;
  fileWebPath: string;
}

// New Models

export interface UserEditPasswordModel {
  userId: number;
  password: string;
  confirmPassword: string;
}

export interface UserEditPartnerDataModel {
  id: number;
  isPartner: boolean;
  commercialPercentageFee: number;
  executionPercentageFee: number;
  paymentOption: PaymentOptions;
  automaticInvoicing: boolean;
  isBillingDataCompleted: boolean;
}

export type UserPartnerDataModel = UserEditPartnerDataModel;
